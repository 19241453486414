import React, { useState } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { FaAffiliatetheme, FaChild, FaFlask, FaQuestion, FaClipboardList, FaBalanceScaleLeft, FaBalanceScale, FaBalanceScaleRight, FaCommentsDollar, FaDollarSign, FaCalendarWeek, FaFish, FaTools, FaBookOpen } from 'react-icons/fa';
import { FaBeer } from 'react-icons/fa';

export const Timeline = () => {
  const background = "linear-gradient(to right bottom, #0d2b59, #261d3f, #241427, #1a0b14, #000000)"

  return (
    <VerticalTimeline>
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      date="26th August 2021"
      icon={<FaFlask />}
    >

      <h2 >Planning Breeding of Secret Pond</h2>

      <p>
      The age of men is over. The time of the Koi has come. The pond's population is growing rapidly and will soon reach its objective of 376 unique, hand-drawn Koin Koi. A lot of breeding needs to be done to fullfill the big pond dream: To have bred only the most fabulous and amazing Koi and release them in the Solana Blockchain.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaAffiliatetheme />}
    >
      <h2 >Trait &amp; meme contest</h2>

      <p>
      Don't let anyone tell you differently - fish and fashion fit well together. Paddle home, pull the kelp aside and take a look in your wardrobe or attic. What do you find there? What makes a great trait for a Koin Koi? Or are you more of a meme fish?   
      </p>
    </VerticalTimelineElement>

    {/* <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaChild />}
    >
      <h2 >Meme Contest</h2>

      <p>
      Your life in the pond is wonderful, swimming greatly satisfies you. You are a part of the ever growing koimmunity - but how much of a meme fish are you really? Take part in the koitastic meme contest and show your devotion and meme lord skills. Create a meme about Koin Koi and have a chance to win a NFT!  
      </p>
    </VerticalTimelineElement> */}



    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}

      icon={<FaBalanceScaleLeft />}
    >
      <h2 >Early Supporter Presale</h2>

      <p>
      All Koi are loved equally - no exceptions. However some of them joined the pond quite a while ago and were the first ones paddling in it. They have seen the koimmunity grow and have thus earned the right to take part in the pre-sale.
      </p>
    </VerticalTimelineElement>

    {/* <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#822e2e', color: '#fff' }}
      icon={<FaBalanceScale />}
    >
      <h2 >Early Access Sale</h2>

      <p>
      The early fish catches the worm - but there is no fishhook attached to it. This is no bait - the pond is preparing for its big objective: To spread the magnificence of Koin Koi all over the Solana blockchain. Take part in this early access sale and grab your Koin Koi just before the big rush begins!
      </p>
    </VerticalTimelineElement> */}


<VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaClipboardList />}
    >
      <h2 >Rarity Table leaked</h2>

      <p>
      Every Koi is beautiful and deserves to be loved - they do come in many shapes, colors and outfits. But nevertheless you noticed that some traits occur more often than others. And you are pretty sure you have seen some only once during your time in the pond. Now you have certainty and know about the rarity of the individual traits.
      </p>
    </VerticalTimelineElement>

<VerticalTimelineElement
      className="vertical-timeline-element--work"

      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaBalanceScale />}
    >
      <h2>OG Collection</h2>
      <p>
      The early fish catches the worm - but there is no fishhook attached to it. This is no bait - the first 30 OGs had time to breed a customized koi that previously only existed in their imagination. How special they truly are is to be revealed in the future.
      </p>
    </VerticalTimelineElement>




    <VerticalTimelineElement
      className="vertical-timeline-element--work"

      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaBalanceScaleRight />}
    >
      <h2 >Mainsale</h2>
      <p>
      The tide finally has risen and the streaming river of enthusiasm has made to pond bursting its banks - the Koi are free! And they're ready to see the world. Nothing can stop the stampede of fins. This is the moment we worked for so hard. 376 unique Koin Koi have made their way to the Solana blockchain. Take your chance and show that you are a true Koi.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaCommentsDollar />}
    >
      <h2 >List on different marketplaces for conquering ponds all over the world</h2>

      <p>
      Now there is nothing to stop the Koi from conquering ponds all over the world. Fishy greatness is to be seen all around various marketplaces. To in-fin-ity and beyond!
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#0d242e', color: '#fff' }}
      icon={<FaFish />}
    >
      <h2 >Living pond release</h2>

      <p>
      The neighbours grass is greener? His pond is certainly not bigger and Koi are everything that matters. The release of Pond NFTs allows you now to display your pond and everything that paddles within to the whole world.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#226f74', color: '#fff' }}
      icon={<FaTools />}
    >
      <h2 >Pond customization</h2>

      <p>
      Not only every single koi is unique - let's be honest, each pond should be! A living pond as it is in your imagination will need releases and airdrops of new inhabitants, decoration and even music.
      </p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#226f74', color: '#fff' }}
      icon={<FaBookOpen />}
    >
      <h2 >Visual Novel</h2>
      <p>
      A visual novel with a unique koi story and your own koi as the MC? Let the kois tell the story the pond deserves to hear.
      <br/>Yep, this will be fun!
      </p>
    </VerticalTimelineElement>

    {/* <VerticalTimelineElement
      className="vertical-timeline-element--work"
      
      contentArrowStyle={{ borderRight: '7px solid  rgb(255, 255, 255)' }}
      iconStyle={{ background: '#226f74', color: '#fff' }}
      icon={<FaCalendarWeek />}
    >
      <h2 >Koimmunity Events - to decided together</h2>

      <p>
      It's not only about having a Koin Koi, it's about the Koimmunity, without whom the Koi Story wouldn't have been as great as it has been. As we want to give something back to them, we're planning to have some events for our koimmunity members - if you like!
      </p>
    </VerticalTimelineElement> */}


    

  </VerticalTimeline>

  )
}