
import * as anchor from "@project-serum/anchor";
import "./index.css";

import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { idMintMap, koiMints } from "../../mintData";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Redirect } from 'react-router'
import {
  HashLink as Link
} from "react-router-hash-link";
import { Bubbles } from "../Bubbles";
//Some of the sources:
//https://spl.solana.com/token#finding-all-token-accounts-for-a-specific-mint
import { fadeInUp } from 'react-animations';
import styled, { keyframes } from "styled-components";
import Collapsible from 'react-collapsible';
import { LoadingSpinner } from "../LoadingSpinner";
import { useEffect, useState } from "react";
import { getMetadata } from "../Web_Requests/get-meta";
import { Input } from "@material-ui/core";


const FadeInAnimation = keyframes`${fadeInUp}`;
const FadeInDiv = styled.div`
animation: 1s ${FadeInAnimation};
`;
export interface PondDirectoryProps {
  connection: anchor.web3.Connection;
  txTimeout: number;
  //mintAnchor: anchor.web3.PublicKey; //MintAnchor describes the mintHash that is used to detect the corresponding wallet
}




export const PondDirectory = (props: PondDirectoryProps) => {



  const defaultLoadingMessage = "Rendering your pond...";
  const defaultErrorMessage = "Whoops, something went wrong! :-(";
  const [isLoading, setIsLoading] = useState(false);
  const [filterStr, setFilterStr] = useState<string>("");
  const [ogFilterStr, setOgFilterStr] = useState<string>("");
  const [loadingMessage, setLoadingMessage] = useState<string>(defaultLoadingMessage);
  const [isError, setIsError] = useState(false);
  const [isOg, setIsOg] = useState(false);
  const [ogKoiName, setOgKoiName] = useState("");
  const [noKoiDetected, setNoKoiDetected] = useState(false);
  const [pondURI, setPondURI] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>(defaultErrorMessage);
  const [is404, setIs404] = useState(false);
  const [nftsLoaded, setNftsLoaded] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [parsedProgramAccounts, setParsedProgramAccounts] = useState<any>();
  const [anchorWallet, setAnchorWallet] = useState<anchor.web3.PublicKey>();
  const [nfts, setNfts] = useState<any[]>();
  const [mintAnchor, setMintAnchor] = useState<anchor.web3.PublicKey | string>();
  const programAccountKey = new anchor.web3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");
  const endpoint = "https://explorer-api.mainnet-beta.solana.com";
  const wallet = useAnchorWallet();
  
  useEffect(() => {
    const fetchMintsWithMetadataPart2 = async () => {

    if(wallet && !nftsLoaded){
      console.log(nfts);
    //Get all Token Accounts of this wallet
    try {
      
      setLoadingMessage("Fetching token accounts...");
      console.log("Requesting all token accounts for this anchor wallet: " + wallet.publicKey.toBase58());
      const tokenAccounts = await props.connection.getParsedProgramAccounts(programAccountKey,
        //const result = await props.connection.getParsedProgramAccounts(programAccountKey,
        {
          filters:
            [{ dataSize: 165 },
            { memcmp: { offset: 32, bytes: wallet.publicKey.toBase58() } }]
        });
      setParsedProgramAccounts(tokenAccounts); //Save them TODO: Waste of Memory?
      //console.log(result);
  
  
      //Now: Iterate through all Accounts, check if supply is ===1 (so if the Account actually owns the mint right now)
      const nftArray: any[] = [];
      console.log("Filtering for TokenAccounts holding a Koi NFT...");
      setLoadingMessage("Fishing for koi in your pond...");
      
      for (var i = 0; i < tokenAccounts.length; i++) {
        
        if (tokenAccounts[i].account.data["parsed"]["info"]["tokenAmount"]["amount"] == "1") {
          //nftArray.push(result[i].account.data["parsed"]["info"]["mint"]);
  
          //As we found a valid Token Account, try to read the Metadata of the token itself aswell as the Arweave Metadata. (Should also work with IPFS)
          try {
            if(!(tokenAccounts[i].account.data["parsed"]["info"]["mint"] in koiMints)){  //Skip everything that is not a koi.
              continue;
            }
            console.log("Detected koi:");
            console.log(tokenAccounts[i].account.data["parsed"]["info"]["mint"]);
            setLoadingMessage("Detected koi: " + (nftArray.length + 1) );
            const mintKey = await new anchor.web3.PublicKey(tokenAccounts[i].account.data["parsed"]["info"]["mint"]);
              
            const tokenMetadata = await getMetadata(mintKey, endpoint);
  
            const arweaveData = await fetch(tokenMetadata.data.uri).then((res) =>
              res.json().catch() //Parse the received Json
            ).catch((error) => {
              console.log("Error fetching Arweave Data."); //Oh neim
              // mints.push({tokenMetadata, failed: true})
            });
            //Push received data nicely into the nftArray (resultArray)
            nftArray.push({
              tokenData: {
                ...tokenMetadata.data,
                creators: tokenMetadata.data.creators.map((d) => {
                  return {
                    share: d.share,
                    address: new anchor.web3.PublicKey(d.address).toBase58(),
                    verified: !!d.verified,
                  };
                }),
              },
              metadata: arweaveData,
              mint: mintKey,
            });
            
            //const metadata = await props.connection.getParsedAccountInfo(mintKey);
            //const metadata = await getFetchFullMetadataFunction(endpoint, result[i].account.data["parsed"]["info"]["mint"]);
  
            //console.log("Current Mint: " + result[i].account.data["parsed"]["info"]["mint"])
            //nftArray.push(metadata);
          }
          catch (error) {
            console.log("Error for this Token Account: ");
            console.log(tokenAccounts[i]);
            setLoadingMessage(String(error));
  
          }
        }
        //console.log("Mint: " + result[i].account.data["parsed"]["info"]["mint"])
        //console.log("Supply: " + result[i].account.data["parsed"]["info"]["tokenAmount"]["amount"]);
      }
      setNfts(nftArray); //Save the NFTs ser
      setNftsLoaded(true);
      if(nftArray.length == 0){
        setNoKoiDetected(true);
      }
      console.log("Valid NFTs:");
      console.log(nftArray);
    } catch (error) {
      console.log("Error during fetching your token accounts and mint metadata.");
      console.log(error);
      setIsError(true);
      setErrorMessage("Critical error when trying to find associated koi :-(")
    }
    setIsLoading(false);
    setLoadingMessage(defaultLoadingMessage);
    
  };
  }
  fetchMintsWithMetadataPart2();
  
  }, [props.connection, wallet, nftsLoaded]);


  useEffect(() => {
    var possibleURIs = []
    if(nfts && nfts.length >= 1){
      for(var i=0; i<nfts.length; i++){
        let mintHash = nfts[i]["mint"].toBase58();
        if(isNaN(koiMints[mintHash])){
          console.log("Detected OG: " + koiMints[mintHash]);
          //OG koi
          setIsOg(true);
          setOgKoiName(koiMints[mintHash].replace("-", " "))
          setPondURI("pond-" + koiMints[mintHash]);
          return;
        }
      }
      let mintHash = nfts[0]["mint"].toBase58();
      setPondURI("pond-" + koiMints[mintHash]);
    }
    else{
      setIsOg(false);
      setPondURI("");
    }
  }, [nfts, pondURI]);

const ponds = Object.keys(idMintMap)
//@ts-ignore
.filter(id => !isNaN(id))
  .filter(e => e.includes(filterStr))
  .map(id => 
    //<option value={key}>{tifs[key]}</option>
    <Link className="pondLink gridItem hover-underline-animation-yellow" to={"pond-"+id}>Koin Koi #{id}</Link> // renders <a href="/calendar/tomorrow">
);

const og_ponds = Object.keys(idMintMap)
//@ts-ignore
.filter(id => isNaN(id))
.filter(e => e.includes(ogFilterStr))
.map(id => 
  //<option value={key}>{tifs[key]}</option>
  <Link className="pondLink gridItem hover-underline-animation-yellow" to={"pond-"+id}>{koiMints[idMintMap[id]].replaceAll("-", " ", )}</Link> //Restore Whitespace instead of "-", also use names from koiMints array, because they weren't lowercased.
);

const onKoiFilterChange = (e) => {
  setFilterStr(e.target.value) ;
  //setOgFilterStr("") ;
}

const onOgFilterChange = (e) => {
  //setFilterStr("") ;
  setOgFilterStr(e.target.value) ;
}


  return(
  <FadeInDiv className="fullPondDirWrapper constructionHeadings">
    <div className="schnabulatorDiv">
            {/* <LottieTurtle/> */}
            <Bubbles/>
            <img className="imageDecoration" src="img/schnabulator_flipped.png"></img>
          </div>
          <div className="lillyDiv">
            {/* <LottieTurtle/> */}
            <Bubbles/>
            <img className="imageDecoration" src="img/lilly_flipped.png"></img>
          </div>

      {!wallet ? (
            <WalletDialogButton id="bodyConnectButton">
            <span className="welcomeHeadings animated-button7">
             Connect to auto-detect your pond.

             </span>
             </WalletDialogButton>

          ) : 
          
          (
            
            
            <div>
              
              {!nfts ? (
              <div>
              {/* <LoadingSpinner color1="#11d43b" color2="#11d43b"/> */}

              <span  className="gridItem animated-button7">
                <a className="loading gridItem">Detecting your pond</a>

                </span>
                          
           

              
              </div>
              ) : (
                <div>
                {noKoiDetected ? (
                  <h1>Couldn't detect any Koin Koi NFTs in your connected wallet :-(
                    <br/>
                    You can use your Koi ID to find it manually or explore other ponds here!

                  </h1>

                ) : ( //Koi detected, now check if a OG is contained
                      <div>
                        {isOg && ogKoiName!="" ? ( //Koi detected, OG detected
                        <h1 className="noSelect">Hello '{ogKoiName}' OG!</h1>
                      ) : ( //Koi dtected, no OG detected
                        <h1 className="noSelect">Hello Koi holder!</h1>
                      )}
                      <div>
                          <span  className="animated-button7">
                          <Link className="gridItem hover-underline-animation-yellow" to={"/" + pondURI.toLowerCase()}>Visit your pond!</Link>
                            <div id="animated-button7">
                            <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                              </div>
                            </span>
                          
                        </div>
                    </div>
                
                )
                
                }
                </div>
                /*
                  {isOg ? (
                  <h1>Hello Og!</h1>
                ) : (
                  <div>
                    <h1>Hello Non-Og!</h1>
                    <Link className="pondLink gridItem hover-underline-animation-yellow" to={"/" + pondURI}>Go to your pond!</Link>
                  </div>
                )}
                */
                
              
              )
              }
              
            </div>

          )
            
      }

    <h1 className="pondDirHeader"></h1>
    <Collapsible className="noSelect" trigger="Discover OG ponds!">
    <Input
    className="filterGrid"
        placeholder="Find a OG koi"
        type="text"
        value={ ogFilterStr }
        onChange={ e => onOgFilterChange(e)} />
        <br/>
      <br/>
    <div className="dirWrapper">
      {og_ponds}
    </div>
    </Collapsible>

    <Collapsible className="noSelect" trigger="Discover all ponds!">
    <Input
        className="filterGrid"
        placeholder="Find your koi"
        type="text"
        value={ filterStr }
        style={{textAlign: "center"}}
        onChange={ e => onKoiFilterChange(e)} />
      <br/>
      <br/>

    <div className="dirWrapper">
      {ponds}

    </div>
    </Collapsible>
    <br/>
  </FadeInDiv>)
};



