import React from 'react';
import { stack as Menu } from 'react-burger-menu'
import {
  HashLink as Link
} from "react-router-hash-link";
import { SocialIcon } from 'react-social-icons';
import $ from 'jquery';
import './index.css'
export class BurgerMenu extends React.Component {

  scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    var header = $(".header").outerHeight() || 0;
    const yOffset = -header-50;
    console.log(yOffset);
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}



  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu right >
        <Link to="/#"> Home </Link>   

        <Link scroll={this.scrollWithOffset} to="/#pondmapHeader"> Roadmap </Link>

        <Link scroll={this.scrollWithOffset} to="/#faqHeader"> FAQ </Link>

        <Link scroll={this.scrollWithOffset} to="/#teamHeader"> Team </Link>


        <Link scroll={this.scrollWithOffset} to="/ponds"> Ponds </Link>

          <div id="socialBurgerDiv">
          
            
            <SocialIcon target="_blank" url="https://twitter.com/KoinKoi_io" label="Koin Koi Twitter" style={{ width: 60, height: 60, margin: 10}}></SocialIcon>

            <SocialIcon target="_blank" url="https://discord.gg/Re4ADVBjV5" label="Koin Koi Discord" style={{ width: 60, height: 60, margin: 10}}></SocialIcon>

            <SocialIcon target="_blank" url="https://medium.com/@mail_51887/the-koi-story-a-solana-nft-project-76c689bd166f" label="Medium Story" style={{ width: 60, height: 60, margin: 10}}></SocialIcon>
    
            <p style={{fontSize: "20px", padding: "5px"}}>&copy; Koin Koi {new Date().getFullYear()}</p>
          </div>

      </Menu>
    );
  }
}