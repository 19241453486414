// @ts-ignore
export const marketAddresses = 
{
  "F4ghBzHFNgJxV4wEQDchU5i7n4XWWMBSaq7CuswGiVsr" : "Digital Eyes",
  "GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp" : "Magic Eden",
  "3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK" : "Solanart",
  "4pUQS4Jo2dsfWzt3VgHXy3H6RYnEDd11oWPiaM2rdAPw" : "Alpha Art",
}

export const getItemLink = (marketAddress, nftHash) => {
  if(marketAddress == "GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp"){
    return ("https://magiceden.io/item-details/" + nftHash)
  }
  return false;
}