import "./App.css";
import { useMemo } from "react";

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Pond } from "./components/Pond";
import { BurgerMenu } from "./components/BurgerMenu";
import AnchorLink from "antd/lib/anchor/AnchorLink";
import { PondDirectory } from "./components/PondDirectory";
import { OceanWaves } from "./components/OceanWaves";




const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost, "confirmed");

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
    palette: {
        type: 'dark',
    },
    overrides: {
        MuiButtonBase: {
            root: {
                justifyContent: 'flex-start',
            },
        },
        MuiButton: {
            root: {
                textTransform: undefined,
                padding: '12px 16px',
            },
            startIcon: {
                marginRight: 8,
            },
            endIcon: {
                marginLeft: 8,
            },
        },
    },
});

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
      <ThemeProvider theme={theme}>
        
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletDialogProvider>
              
              <Router basename={process.env.PUBLIC_URL}>
              <div id="burgerWrapper">
                <BurgerMenu/>
              </div>
              <Header/>            

                  {/* <KoiHR/> */}
                  {/* <div className="imageDecorationWrapper">
                  <img className="imageDecoration" src="http://localhost:3000/img/schnabulator.png"></img>
                  </div> */}
                <Switch>
                <Route path="/ponds" component={PondDirectory} exact>
                
                      <div className="bodyFooterWrapper">
                        <PondDirectory
                          connection={connection}
                          txTimeout={txTimeout}
                        />
                        <Footer footerClass={"footer_abs"}/>
                        </div>
                      </Route>  
                <Route path="/pond-:id" component={Pond}>
                  
                      <div className="bodyFooterWrapper">
                      
                        <Pond
                          connection={connection}
                          txTimeout={txTimeout}
                          //mintAnchor={new anchor.web3.PublicKey("BFNnJdh4YH1U8bxhedntfEZZB6Bz44cbQZrHYbGbtHGV")}
                        />
                        </div>
                      </Route>
                      <Route path="/">
                        <div className="bodyFooterWrapper">
                          
                          <Home
                            candyMachineId={candyMachineId}
                            config={config}
                            connection={connection}
                            startDate={startDateSeed}
                            treasury={treasury}
                            txTimeout={txTimeout}
                            
                          />
                          <Footer footerClass={"footer"}/>
                        </div>
                      </Route>
                  </Switch>
                  
                
            </Router>
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
  );
};

export default App;
